import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import MemoriesCarrousel from '../MemoriesCarrousel/MemoriesCarrousel';
import DialogMessage from 'components/UI/DialogMessage/DialogMessage';
import DropZoneComponent from '../../UI/DropZoneComponent/DropZoneComponent';
import UserButton from '../../UI/UserButton/UserButton';
import DialogNoUser from 'components/UI/DialogNoUser/DialogNoUser';
import Loading from 'components/UI/Loading/Loading';

import { uploadMultimediaTribut } from 'store/uploadFileS3-actions';
import { isLogged } from 'store/user-actions';
import {
  createMemory,
  getMemoryCredentialsUploadImageS3
} from 'store/memory-actions';

import classes from './AddMemory.module.css';

const AddMemory = ({
  tributId,
  open,
  handleClose,
  userData,
  acceptFiles
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [messageDialog, setMessageDialog] = useState('');
  const [message] = useState();
  const [loadFile, setLoadfile] = useState(false);
  const [availablePublishBtn, setavailablePublishBtn] =
    useState(false);
  const [files, setFiles] = useState(null);
  const [multimedias, setMultimedias] = useState([]);
  const [totalFiles, setTotalFiles] = useState(null);
  const [view, setView] = useState(null);
  const [loadingFiles, setLoadingFiles] = useState(false);

  useEffect(() => {
    if (multimedias.length === totalFiles) {
      setavailablePublishBtn(true);
      setLoadingFiles(false);
    }
  }, [totalFiles, multimedias]);

  if (isLogged() && view == null) {
    setView('UPLOAD');
  } else if (view == null) {
    setView('LOGIN');
  }

  const changeViewHandler = async () => {
    setView('UPLOAD');
  };

  const handleDrop = async data => {
    setTotalFiles(data.length);
    setLoadingFiles(true);
    setMultimedias([]);

    data.forEach(function (item) {
      // Set fileName
      item.fileName = Date.now();
      let fileNameExt = item.type;
      let fileNameSplit = fileNameExt.split('/');
      // Set fileExtension
      item.fileExtension = fileNameSplit[1];
      const preview = URL.createObjectURL(item);
      console.log('🚀 ~ preview:', preview);
      const reader = new FileReader();
      reader.onload = function (e) {
        let data = {
          file: e.target.result,
          extension: fileNameSplit[1],
          preview: preview
        };
        setMultimedias(multimedias => [...multimedias, data]);
      };
      reader.readAsDataURL(item);
    });

    setFiles(data);
  };

  const closeDialog = () => {
    handleClose();
    setView(null);
    setMultimedias([]);
    setTotalFiles(null);
    setavailablePublishBtn(false);
  };

  const createMemoryAction = async () => {
    let upload = false;
    setLoadfile(true);
    for (let file of files) {
      let memoryId = await createMemory(tributId, message, file);
      if (memoryId) {
        let credentials = await getMemoryCredentialsUploadImageS3(
          tributId,
          memoryId,
          file.type,
          file.fileName,
          file.fileExtension
        );
        let uploaded = await uploadMultimediaTribut(
          credentials,
          file
        );

        if (uploaded) {
          upload = true;
        } else {
          upload = true;
        }
      }
    }
    if (upload === true) {
      closeDialog();
      setShowDialog(true);
      setMessageDialog('Las Memorias fueron publicadas con éxito');
    } else if (upload === 'error') {
      closeDialog();
      setShowDialog(true);
      setMessageDialog(
        'Hubo un error, te sugerimos volver a intentarlo'
      );
    }
  };

  const closeDialogHandler = () => {
    setLoadfile(false);
    setView('UPLOAD');
    setMultimedias([]);
    setShowDialog(false);
  };

  return (
    <>
      {view === 'UPLOAD' && (
        <Dialog fullWidth open={open} onClose={closeDialog}>
          <DialogTitle className={classes.title}>
            Crear una Memoria
            <Button
              className={classes.closeModalBtn}
              onClick={closeDialog}
            >
              <ClearIcon className={classes.closeModalIcon} />
            </Button>
          </DialogTitle>
          <DialogContent>
            <aside className={classes.userInfoConatiner}>
              {userData && (
                <>
                  {userData.avatar ? (
                    <figure className={classes.userImage}>
                      <img
                        className={classes.img}
                        alt={
                          userData
                            ? userData.firstName
                              ? userData.firstName
                              : ''
                            : 'UserName'
                        }
                        src={userData.avatar}
                      />
                    </figure>
                  ) : (
                    <div className={classes.noUserContainer}>
                      <PersonOutlinedIcon
                        className={classes.noUserIcon}
                      />
                    </div>
                  )}
                  <p className={classes.userName}>
                    {userData.firstName} {userData.lastName}
                  </p>
                </>
              )}
            </aside>
            {multimedias.length > 0 ? (
              <MemoriesCarrousel data={multimedias} />
            ) : (
              <DropZoneComponent
                onDrop={handleDrop}
                acceptFiles={
                  acceptFiles === 'all'
                    ? 'image/*,audio/*,video/*, .heic'
                    : 'video/*'
                }
                type="multiple"
                multipleFiles="true"
                multimedias={multimedias}
                memory
              />
            )}

            {!loadFile ? (
              <>
                {loadingFiles ? (
                  <Loading />
                ) : (
                  <UserButton
                    edit
                    disabled={!availablePublishBtn}
                    onClickHandler={createMemoryAction}
                    text="Publicar"
                  />
                )}
              </>
            ) : (
              <p className={classes.messageText}>
                No cambie de pantalla, esto puede afectar la carga
                del archivo
              </p>
            )}
          </DialogContent>
        </Dialog>
      )}
      <DialogMessage
        closeDialog={closeDialogHandler}
        open={showDialog}
        message={messageDialog}
      />
      {view === 'LOGIN' && (
        <DialogNoUser
          open={open}
          closeDialog={closeDialog}
          action={changeViewHandler}
        />
      )}
    </>
  );
};

AddMemory.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  handleClose: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  userData: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  acceptFiles: PropTypes.string
};

export default AddMemory;
